import axios from 'axios';

export const serverInstance = axios.create({
    baseURL: 'api/',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    withCredentials: true,
});

export const apiAxiosInstance = axios.create({
    baseURL: 'https://api.wi-flix.com',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});
