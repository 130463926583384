import { LoadingButton as Button } from '@mui/lab';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Input } from '../../components/Input';
import { withRoot } from '../../components/Root';
import { useFragmentContext } from '../../hooks/useFragmentContext';
import { Checkout as Layout } from '../../layouts/Checkout';
import { serverInstance } from '../../utils/axiosInstance';

const useStyles = makeStyles(theme => ({
    section: {
        flex: 1,
        marginBottom: theme.spacing(3),
        padding: [0, theme.spacing(2)],
        '& form': {
            flex: 1,
        },
    },
    base: {
        marginBottom: theme.spacing(3),
        '& input': {
            fontSize: 14,
        },
    },
    input: {
        padding: theme.spacing(0.5),
    },
    btnWrapper: {
        position: 'relative',
        marginTop: theme.spacing(3),
        background: 'none',
    },
    redeemBtn: {
        textTransform: 'none',
        background: '#ed532d',
        fontSize: 16,
        color: '#FFF',
        '&:hover': {
            color: '#ed532d',
        },
    },
    disabledBtn: {
        backgroundColor: '#cccccc!important',
        color: '#666666!important',
    },
    error: {
        fontSize: '1.6rem!important',
    },
}));

function PromptMessage({ user, code }) {
    const classes = useStyles();
    const history = useHistory();
    const codeURI = encodeURIComponent(`/vouchers?code=${code}`);

    const getLogged = async () => {
        const loc = {
            pathname: '/login',
            search: `referrer=${codeURI}`,
        };

        if (user) {
            await serverInstance.post('sessionLogout');
            window.location.href = `/login?referrer=${codeURI}`;
        } else {
            history.push(loc);
        }
    };

    return (
        <section className={classes.section}>
            <div style={{ display: 'flex' }}>
                <p style={{ display: 'inline-block', fontSize: 17, flex: 1 }}>
                    {user ? user.phone_number : 'Please login to redeem voucher'}
                </p>
                <Button
                    onClick={getLogged}
                    variant="outlined"
                    style={{
                        borderColor: '#ed532d',
                        color: 'white',
                        margin: 'auto',
                        height: '50%',
                    }}
                >
                    {user ? 'Log Out' : 'Log In'}
                </Button>
            </div>
        </section>
    );
}

function Voucher(props) {
    const classes = useStyles();

    const { location } = props;
    const { user } = useFragmentContext();

    const searchCode = queryString.parse(location.search);
    const [submitErrorMessage, setSubmitErrorMessage] = useState('');
    const [search, setSearch] = useState(searchCode);

    const onChangeCode = value => {
        setSearch(prev => ({
            ...prev,
            voucher: value,
        }));
    };

    const redeemVoucher = async () => {
        try {
            const voucherCode = (search.voucher.toUpperCase() || '').trim();
            const { data } = await serverInstance.post(`vouchers/redeem/${voucherCode}`);
            const redirectUrl = data?.redirect_to || data?.redirect_url;
            if (redirectUrl) {
                window.open(redirectUrl, '_self');
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'Failed to redeem voucher';
            setSubmitErrorMessage(errorMessage);
        }
    };

    const formik = useFormik({
        initialValues: { ...search },
        validateOnChange: false,
        onSubmit: redeemVoucher,
    });

    const { values, errors, handleChange } = formik;
    const onChange = (name, value) => {
        onChangeCode(value);
        handleChange({ target: { name, value } });
    };

    return (
        <Layout>
            <div>
                <PromptMessage user={user} code={search.code || ''} />
                <section className={classes.section}>
                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className={classes.base}>
                            <Input
                                fullWidth
                                variant="outlined"
                                type="text"
                                name="voucher_code"
                                value={values.code || ''}
                                placeholder="Voucher Code"
                                error={errors.code}
                                onChange={onChange}
                                classes={{ textFieldRoot: classes.input }}
                            />
                        </div>

                        {Boolean(submitErrorMessage) && (
                            <FormHelperText error classes={{ error: classes.error }}>
                                {submitErrorMessage}
                            </FormHelperText>
                        )}

                        <div className={classes.btnWrapper}>
                            <Button
                                loading={formik.isSubmitting}
                                fullWidth
                                type="submit"
                                variant="contained"
                                disabled={!user || formik.isSubmitting}
                                classes={{
                                    root: classes.redeemBtn,
                                    disabled: classes.disabledBtn,
                                }}
                            >
                                Redeem Voucher
                            </Button>
                        </div>
                    </form>
                </section>
            </div>
        </Layout>
    );
}

Voucher.getInitialProps = async () => {
    await Promise.resolve();
};

Voucher.getChunkName = () => {
    return 'Voucher';
};

const VoucherRoute = withRoot(Voucher);

export { VoucherRoute as Voucher };
