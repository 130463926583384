export const getErrorContent = err => {
    let errValue = err;
    if (Array.isArray(err)) {
        const errStrings = err.map(e => e.message || e.errorMessage).filter(Boolean);
        return errStrings.join('\n');
    }

    if (typeof err !== 'string') {
        if (Object.hasOwnProperty.call(err, 'message')) {
            errValue = err.message;
        } else if (Object.hasOwnProperty.call(err, 'error')) {
            return getErrorContent(err.error);
        } else {
            errValue = JSON.stringify(err);
        }
    }

    return errValue;
};
