import { useState, useEffect } from 'react';
import { getDatabase, onValue, query, ref } from 'firebase/database';

import { firebaseApp } from '../../firebase-app';
import { Subscription } from '../../../shared/types/subscription';

const database = getDatabase(firebaseApp);

type UseSubscriptionReturn = {
    loaded: boolean;
    subscription?: Subscription;
};

function useSubscription(id: string): UseSubscriptionReturn {
    const [loaded, setLoaded] = useState(false);
    const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);

    useEffect(() => {
        if (id && !loaded) {
            const orderQuery = query(ref(database, `subscriptions/${id}`));
            onValue(orderQuery, snapshot => {
                setLoaded(true);
                if (snapshot.exists()) {
                    setSubscription(snapshot.val());
                }
            });
        }
    }, [id, loaded]);

    return {
        loaded,
        subscription,
    };
}

export { useSubscription };
