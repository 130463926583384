import { useState, useEffect } from 'react';
import { query, ref, getDatabase, onValue } from 'firebase/database';

import { firebaseApp } from '../../firebase-app';

const database = getDatabase(firebaseApp);

function useOrder(id?: string): {
    loaded: boolean;
    order: Record<string, unknown> | undefined;
} {
    const [loaded, setLoaded] = useState(false);
    const [order, setOrder] = useState<Record<string, unknown> | undefined>(undefined);

    useEffect(() => {
        if (id && !loaded) {
            const orderQuery = query(ref(database, `orders/${id}`));
            onValue(orderQuery, snapshot => {
                setLoaded(true);
                if (snapshot.exists()) {
                    setOrder(snapshot.val());
                }
            });
        }
    }, [id, loaded]);

    return { loaded, order };
}

export { useOrder };
